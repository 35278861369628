import React from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

import "./Styles.css";

function AboutUs() {
  return (
    <React.Fragment>
      <Header />

      <div
        className="container-fluid solutions-bg"
        id="aboutus"
        style={{ paddingTop: "100px" }}
      >
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-md-10 col-sm-12 text-center">
              <p className="para green-para">
                A vision to reduce carbon emission.
              </p>
              <p
                className="para solution-banner-para"
                style={{ fontSize: "1.1em" }}
              >
                We are on a mission to make industries energy efficient through
                better monitoring and maintenance of machines. Our focus is to
                bring world-class solutions to businesses in a cost-effective
                manner, enabling a growing number of global manufacturers to
                adopt digital technologies to drive operational efficiencies at
                their plants.
              </p>
              <p
                className="para solution-banner-para"
                style={{ fontSize: "1.1em" }}
              >
                Headquartered in India, we have offices in Coimbatore, Bangalore
                and Stockholm (Europe). Our aspiration is make a tangible impact
                on the planet by developing cost effective energy solutions. We
                are proud to align with the UN SDG Goal industries, innovation
                and infrastructure.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container solutions-panel">
        <div className="row">
          <div className="col-md-5 col-sm-12">
            <h1
              className="heading white-heading"
              style={{ padding: "50px 40px 30px 40px" }}
            >
              Global Advisors
            </h1>
          </div>
        </div>

        <div className="row advisor">
          <div className="col col-md-4 col-sm-12 advisor-card">
            <div className="row">
              <div className="col-4">
                <img alt="" src={require("./images/advisors_1.png")} width="100%" />
              </div>
              <div className="col-7">
                <span className="advisor-naming">
                  <a
                    href="https://www.linkedin.com/in/hakan-kingstedt-8670359/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Håkan Kingstedt
                  </a>
                </span>
                <p className="para">
                  Chairman of Sweden-India Business Council (SIBC) and the
                  former president of Sandvik Ltd.
                </p>
              </div>
            </div>
          </div>

          <div className="col col-md-4 col-sm-12 advisor-card">
            <div className="row">
              <div className="col-4">
                <img alt="" src={require("./images/advisors_2.png")} width="100%" />
              </div>
              <div className="col-7">
                <span className="advisor-naming">
                  <a
                    href="https://www.linkedin.com/in/rscvedi/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ravi Shankar Chaturvedi
                  </a>
                </span>
                <p className="para">
                  Director of Research, <br />
                  Fletcher School in Boston
                </p>
              </div>
            </div>
          </div>

          <div className="col col-md-4 col-sm-12 advisor-card">
            <div className="row">
              <div className="col-4">
                <img alt="" src={require("./images/advisor3.png")} width="100%" />
              </div>
              <div className="col-7">
                <span className="advisor-naming">
                  <a
                    href="https://www.linkedin.com/in/mathi-gurusamy-69782a1/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Mathi Gurusamy
                  </a>
                </span>
                <p className="para">
                  President &amp; CEO, <br />
                  Mobilogix Group
                </p>
              </div>
            </div>
          </div>

          <div className="col col-md-4 col-sm-12 margin-top20 advisor-card">
            <div className="row">
              <div className="col-4">
                <img alt="" src={require("./images/advisors_4.png")} width="100%" />
              </div>
              <div className="col-7">
                <span className="advisor-naming">
                  <a
                    href="https://www.linkedin.com/in/mrajagopalan1/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Murali Rajagopalan
                  </a>
                </span>
                <p className="para">
                  Managing Director, <br />
                  Mobilogix, India
                </p>
              </div>
            </div>
          </div>

          <div className="col col-md-4 col-sm-12 margin-top20 advisor-card">
            <div className="row">
              <div className="col-4">
                <img alt="" src={require("./images/advisors_5.png")} width="100%" />
              </div>
              <div className="col-7">
                <span className="advisor-naming">
                  <a
                    href="https://www.linkedin.com/in/ilkka-hakala-6923992a/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    IIkka Hakala
                  </a>
                </span>
                <p className="para">
                  Co-owner, <br />
                  Karhula Foundry, Finland.
                </p>
              </div>
            </div>
          </div>

          <div className="col col-md-4 col-sm-12 margin-top20 advisor-card">
            <div className="row">
              <div className="col-4">
                <img alt="" src={require("./images/advisors_6.png")} width="100%" />
              </div>
              <div className="col-7">
                <span className="advisor-naming">
                  <a
                    href="https://www.linkedin.com/in/b-c-rao-b9142886/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Bhagya Chandra Rao
                  </a>
                </span>
                <p className="para">
                  Former Managing Director of Kennametal India Limited; Former
                  President, Sandvik mining &amp; construction Rock Tools
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid grey-bg">
        <div className="container">
          <div className="col-md-12 text-center brand-heading">
            Key Achievements
          </div>
          <div className="col-md-12 text-left">
            <div className="row" style={{ margin: "0 auto" }}>
              <div className="col-md-4 col-sm-12 achievements">
                <div className="white-box-container">
                  <span>
                    <img alt="" src={require("./images/achievement_1.png")} />
                  </span>
                  <h2 className="sub-heading" style={{ fontSize: "1.2em" }}>
                    <a
                      href="https://smartcompressor.utvyakta.com/login/login"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Product market fit with compressor manufacturers,
                      distributors and industry end users
                    </a>
                  </h2>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 achievements">
                <div className="white-box-container">
                  <span>
                    <img alt="" src={require("./images/achievement_2.png")} />
                  </span>
                  <h2 className="sub-heading" style={{ fontSize: "1.2em" }}>
                    <a
                      href="https://www.linkedin.com/posts/globalinnovationandtechnologyalliance_india-sweden-sustainabledevelopmentgoals-activity-6777840806993653760-UwtW/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Recipient of Indian-Sweden government grant from DST,
                      India and Vinnova, Sweden to build prototype for real-time
                      energy monitoring of foundries.
                    </a>
                  </h2>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 achievements">
                <div className="white-box-container">
                  <span>
                    <img alt="" src={require("./images/achievement_3.png")} />
                  </span>
                  <h2 className="sub-heading" style={{ fontSize: "1.2em" }}>
                    <a
                      href="https://t-hub.co/raytheon-t-hub-rtxinnovationchallenge/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Among the top 3 of 80 finalists at T-Hub and Raytheon
                      Technologies Innovation challenged to build PoC for
                      quality inspection of Pratt &amp; Whitney 2500 engines.
                    </a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row row-reverse">
          <div className="col-md-6 col-sm-12 padding-50 mobile-left-padding">
            <h1
              className="heading white-heading mobile-only"
              style={{ padding: "10px 40px 30px 40px" }}
            >
              Custom R&amp;D solutions
            </h1>
            <img alt="" src={require("./images/about_us_grid.png")} width="100%" />
          </div>
          <div className="col-md-6 col-sm-12">
            <h1
              className="heading white-heading desktop-only"
              style={{ padding: "50px 40px 30px 40px" }}
            >
              Custom R&amp;D solutions
            </h1>
            <h2
              className="sub-heading mobile-top-20"
              style={{ fontSize: "1.2em" }}
            >
              At Utvyakta Solutions, we specialize in end-to-end solutions –
              from data acquisition to visualisation using AI to track assets
              anytime, anywhere because we strongly believe that maintenance
              should be proactive and not reactive.
              <br />
              <br />
              We are currently working on developing custom solutions for
              specific use cases to provide a live feed of critical equipment to
              drive increased performance efficiencies and cost savings. Talk to
              us to understand how we can add value to your operations through
              technology.
            </h2>
          </div>
        </div>
      </div>

      <div className="container-fluid grey-bg team-panel-bg">
        <div className="container">
          <div className="col-12 text-left brand-heading">Core Team</div>
          <div className="col-12 text-left team-panel">
            <div className="row" style={{ margin: "0 auto" }}>
              <div className="col col-md-4 col-sm-12 team-card">
                <div className="row">
                  <div className="col-10 team-heading">
                    Akshay Ramachandran
                    <a
                      href="https://www.linkedin.com/in/akshay-ramachandran-97515342/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="team-linkedin-item"
                    >
                      <img
                        alt=""
                        src={require("./images/in_transparent.png")}
                        width="25px"
                      />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <img alt="" src={require("./images/team_1.png")} width="100%" />
                  </div>
                  <div className="col-7">
                    <p className="para">
                      Founder &amp; Managing Director with Sales, Management
                      &amp; dev experience across India &amp; Europe{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col col-md-4 col-sm-12 team-card">
                <div className="row">
                  <div className="col-10 team-heading">
                    Sudhir Ravindramohan
                    <a
                      href="https://www.linkedin.com/in/sudhirrpublicprofile/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="team-linkedin-item"
                    >
                      <img
                        alt=""
                        src={require("./images/in_transparent.png")}
                        width="25px"
                      />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <img alt="" src={require("./images/team_2.png")} width="100%" />
                  </div>
                  <div className="col-7">
                    <p className="para">
                      Chief Technology Officer with 15 years of global
                      experience across McWane &amp; Oracle
                    </p>
                  </div>
                </div>
              </div>

              <div className="col col-md-4 col-sm-12 team-card">
                <div className="row">
                  <div className="col-10 team-heading">
                    Frederik Vyncke
                    <a
                      href="https://www.linkedin.com/in/frederikvyncke/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="team-linkedin-item"
                    >
                      <img
                        alt=""
                        src={require("./images/in_transparent.png")}
                        width="25px"
                      />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <img alt="" src={require("./images/team_3.png")} width="100%" />
                  </div>
                  <div className="col-7">
                    <p className="para">
                      Co-Founder &amp; Sales Director with leadership,
                      Investments and sales experience globally
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row"
              style={{ margin: "0 auto", justifyContent: "center" }}
            >
              <div className="col col-md-4 col-sm-12 team-card">
                <div className="row">
                  <div className="col-10 team-heading">
                    Prasanna Raghavan
                    <a
                      href="https://www.linkedin.com/in/prasanna-venkatesh-raghavan-24438222/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="team-linkedin-item"
                    >
                      <img
                        alt=""
                        src={require("./images/in_transparent.png")}
                        width="25px"
                      />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <img alt="" src={require("./images/team_4.png")} width="100%" />
                  </div>
                  <div className="col-7">
                    <p className="para">
                      Director for SI projects with product experience in energy
                      management, automotive telematrics &amp; inventory
                      management
                    </p>
                  </div>
                </div>
              </div>

              <div className="col col-md-4 col-sm-12 team-card">
                <div className="row">
                  <div className="col-10 team-heading">
                    Uma Maheswari V
                    <a
                      href="https://www.linkedin.com/in/uma-maheswari-v-431a96147/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="team-linkedin-item"
                    >
                      <img
                        alt=""
                        src={require("./images/in_transparent.png")}
                        width="25px"
                      />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <img alt="" src={require("./images/team_11.png")} width="100%" />
                  </div>
                  <div className="col-7">
                    <p className="para">
                      Manager HR & Admin with 10+ years experience in HR &
                      Administration
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="col col-md-4 col-sm-12 team-card">
                <div className="row">
                  <div className="col-10 team-heading">
                  Betsy Merin Paul
                    <a
                      href="https://www.linkedin.com/in/betsy-merin-paul-419597106/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="team-linkedin-item"
                    >
                      <img
                        alt=""
                        src={require("./images/in_transparent.png")}
                        width="25px"
                      />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <img alt="" src={require("./images/team_12.png")} width="100%" />
                  </div>
                  <div className="col-7">
                    <p className="para">
                    Operations Manager with 5+ years of 
                    experience in handling content, 
                    communications &amp; associated 
                    projects that aligns with the company's goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ margin: "0 auto" }}>
              <div className="col col-md-4 col-sm-12 team-card">
                <div className="row">
                  <div className="col-10 team-heading">
                    Ashwin Paul
                    <a
                      href="https://www.linkedin.com/in/ashwin-paul-47393430/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="team-linkedin-item"
                    >
                      <img
                        alt=""
                        src={require("./images/in_transparent.png")}
                        width="25px"
                      />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <img src={require("./images/team_8.png")} width="100%" alt="preview"  />
                  </div>
                  <div className="col-7">
                    <p className="para">
                      Sales Head with experience across industries such as
                      Steel,IT & Power health & wearables
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default AboutUs;
