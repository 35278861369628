import React, {useState} from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import faqData from "./assets/faqs-question-and-answer";
import "./Faqs.css";

function Faqs() {

  const [isVisible, setIsVisible] = useState(null);

  const handleClick = (id) => {
    setIsVisible(isVisible === id ? null : id);
  }

  return (
    <React.Fragment>
      <Header/>
        <div className = 'faq-main-div'>
          <div className = 'faqs-heading-maindiv'>
            <h2 className = 'faqs-heading'>FREQUENTLY ASKED QUESTIONS</h2>
          </div>
          <div className = 'faq-question-answer-maindiv'>
            {faqData.map((faq) => (
              <div key = {faq.id} className = 'faq-question-answer'>
                <h4 className = "faqs-questions" onClick = {() => handleClick(faq.id)}> {faq.question} </h4>
                  <div className = { `faqs-answers ${isVisible === faq.id ? 'faqs-answers-show' : ''}` }>
                    {isVisible === faq.id && faq.answer.map((answer, index) => (
                      <p key = {index}>
                        {answer.subAnswer ? <p className = "sub-answer-title"> {answer.subAnswer} </p> : answer}
                      </p>
                    ))}
                  </div>
              </div>
            ))}
          </div>
        </div>
      <Footer/>
    </React.Fragment>
  )
};

export default Faqs;