import React from "react";
import Home from "./Home";
import Solutions from "./Solutions";
import AboutUs from "./AboutUs";
import PrivacyPolicy from "./PrivacyPolicy";
import Faqs from "./Faqs";

import {
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/aboutus" element={<AboutUs />} />
          {/* <AboutUs />
          </Route> */}
          <Route path="/solutions" element={<Solutions />} />
          {/* <Solutions />
          </Route> */}
          <Route path="/privacy" element={<PrivacyPolicy />} />
          {/* <PrivacyPolicy />
          </Route> */}
          <Route path="/" element={<Home />} />
          {/* <Home />
          </Route> */}
          <Route path="/home" element={<Home />} />
          {/* <Home />
          </Route> */}
          <Route path="/faqs" element={<Faqs />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
