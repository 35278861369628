
const faqData = [
  {
    id: 1, question: '1. What is Kompress.ai?',
    answer: [
      'Kompress.ai is an exclusive compressor-only software that enables real time monitoring, optimization of compressor operation, a quicker and more efficient service management and energy savings of air compressors.'
    ]
  },
  {
    id: 2, question: '2. What are the components of Kompress.ai Solution?',
    answer: [
      {subAnswer: 'Data Collection:'},
      'Accurate capture and reliable transmission of data parameters from the compressors using Kompress.ai IoT gateway to the Kompress.ai cloud.',
      {subAnswer: 'Data Management:'},
      'Storage and hosting of the raw data from the machine and ingesting it onto the Kompress.ai platform at defined frequencies of time.',
      {subAnswer: 'Data Visualization:'},
      'Visualization of the data on the dashboard and mobile app to provide real-time monitoring and alerts based on rules set on the values. Compressor OEMs and Dealers can manage the services entirely from the Service Management dashboard.'
    ]
  },
  {
    id: 3, question: '3. How does Kompress.ai work?',
    answer: [
      "The compressors are enabled with a controller that has an RS485 port with MODBUS protocol. The Kompress.ai IoT gateway is connected to the Compressor's Controller and monitors the critical and non-critical parameters at a predefined time interval. This typically every 1 minute.",
      'The collected data is then transmitted to the Kompress.ai cloud server thereby enabling real time monitoring & alerts over web portal and mobile application.'
    ]
  },
  {
    id: 4, question: '4. What is the impact of real-time monitoring?',
    answer: [
      'Real-time monitoring of compressor systems offers several key benefits, including early fault detection and proactive maintenance. By continuously tracking parameters like pressure, temperature, and run hours, potential issues can be identified early, allowing timely intervention and minimizing downtime. Proactive maintenance scheduling prevents critical failures, reducing repair costs and avoiding expensive emergency repairs. Additionally, real-time adjustments improve operational efficiency, leading to energy savings and increased uptime. Continuous data collection enables data-driven decision-making, enhancing planning, troubleshooting, and optimizing overall compressor performance.'
    ]
  },
  {
    id: 5, question: '5. Is there any alternate approach to remote monitoring?',
    answer: [
      'Yes, there are alternative approaches to real-time monitoring, but they come with limitations. Alternative approaches to real-time monitoring include manual checks, service logbooks, scheduled inspections, etc. Each method offers insights but has drawbacks, such as time intensity, human error, delayed issue detection, and higher costs. Unlike real-time solutions like Kompress.ai, these methods may lack immediacy and precision in monitoring.'
    ]
  },
  {
    id: 6, question: '6. What are the key benefits of Kompress.ai?',
    answer: [
      'The key benefits of implementing Kompress.ai include but are not limited to',
      'Better maintenance planning',
      'Reduced costs',
      'Increased uptime',
      'Reduced risks',
      'Increased productivity'
    ]
  },
  {
    id: 7, question: '7. What happens if you do not have Kompress.ai for your compressors?',
    answer: [
      'Insufficient monitoring of field compressors',
      'Risk of maintenance overdue',
      'Needless visits to customer sites',
      'Thinking of being safe - potentially leaking costs',
      'Improvements to compressor systems are hard to make without constant monitoring',
      'No clear information on compressor running patterns'
    ]
  },
  {
    id: 8, question: '8. What does Kompress.ai provide to its users?',
    answer: [
      'Run Hour Monitoring of all the compressors in an unified dashboard. Critical Alarms on the Alarm center dashboard helping to address faults on priority. Helps to keep track of continuous monitoring of your compressor KPIs.',
      {subAnswer: 'KPIs:'}, 
      'Pressure - Helps to keep track of Pressure delivered by the compressor',
      'Temperature - Helps to keep track of your compressor\'s operating environment',
      'Utilization % - Helps to check if your compressor is utilized to its maximum',
      'Power Consumption - Helps to keep track of power consumption of your compressor',
      'Total Energy Consumption - Helps to keep track of Energy Consumption in KWH',
      'Fault Information - Get the fault alerts instantly on the dashboard and mobile app',
      'Reports - Generate reports of critical parameters for analysis ',
      'Trends - Understand the patterns that provide more information on how your compressor has operated and how efficient the compressor is.'
    ]
  },
  {
    id: 9, question: '9. If the sensors are not accurate, how will it affect the measurement and analysis?',
    answer: [
      'Kompress.ai uses a hardware gateway that connects to the compressor system\'s digital controller and the data being shown on the compressor\'s HMI at the shopfloor, the same is collected and transmitted to the cloud system and in turn being shown on the visualization platforms.',
      'Monitoring over the Kompress.ai platform is similar to locally operating the compressor on the shop floor. Since the compressor is being operated based on the sensors connected to it, it is highly reliable and whenever there is an issue, it will be reported as a fault by the compressor itself which will be reported through the Alerts engine of Kompress.ai. Moreover the sensors and data controller comes with OEM warranty, so it is generally well maintained and accurate.',
      'In the event of sensors and/or data controllers not present, we provide sensors and meters to be installed that are then able to send the critical parameters, this is usually the case for older generation compressors.'
    ]
  },
  {
    id: 10, question: '10. Can the Kompress.ai software be installed on a private cloud (of individual customers)?',
    answer: [
      'While we understand the desire for private cloud installations, we recommend our cloud-based platform for these key reasons:',
      {subAnswer: '1. Scalability:'}, 
      'Our cloud infrastructure is designed to scale effortlessly as your data grows, ensuring smooth performance and capacity management without the need for additional investment in resources.',
      {subAnswer: '2. Security & Compliance:'}, 
      'Kompress.ai is hosted on a robust, secure cloud environment with up-to-date security measures, compliance standards, and regular audits. This ensures your data is protected using the latest industry practices, which might be more challenging to implement consistently on individual private clouds.',
      {subAnswer: '3. Seamless Updates & Support:'}, 
      'With our cloud solution, you get instant access to the latest features, updates, and improvements with very minimal planned downtime or without any downtime in a few instances. Our dedicated support team can also troubleshoot and address issues more quickly when the solution is hosted centrally.',
      {subAnswer: '4. Cost-Efficiency:'},
      'Hosting Kompress.ai on our cloud reduces your IT overhead and maintenance costs. You won’t need to invest in the hardware, software, or technical expertise required to manage the platform.',
      {subAnswer: '5. Data Integration & Analytics:'}, 
      'Our cloud-based platform facilitates the integration of data from multiple sources, allowing for advanced analytics and real-time insights. This is more effective in a unified cloud setup than in isolated private cloud environments.',
      {subAnswer: '6. Business Continuity:'}, 
      'Our platform ensures reliable data backup, disaster recovery, and uptime guarantees, which are essential for maintaining uninterrupted operations. Hosting Kompress.ai on our cloud not only streamlines the deployment and maintenance process but also ensures that you benefit from a high level of performance, security, and cost savings.'
    ]
  },
  {
    id: 11, question: '11. How does Kompress.ai help in saving energy in the compressed air system?',
    answer: [
      'Kompress.ai is a cloud-based solution that continuously collects and analyzes critical compressor parameters in real-time, such as air pressure, temperature, load hours, run hours, and more. This data-driven approach provides valuable insights to both OEMs and end-users, helping optimize compressor performance, reduce energy consumption, and streamline maintenance planning. By turning raw data into actionable intelligence, Kompress.ai empowers users to make smarter decisions about their operations..',
      {subAnswer: 'For the OEMs:'},
      'Kompress.ai’s ability to collect and analyze data in real-time is a game-changer for OEMs. The platform offers a powerful tool to enhance your service offerings. You can leverage these insights to advise your customers on the optimal settings and preventive maintenance schedules that will help them reduce energy costs and prolong the lifespan of their equipment. This not only improves compressor efficiency but also positions your brand as a forward-thinking leader that delivers more than just equipment—it provides value-added services that drive operational excellence for your clients.',
      {subAnswer: 'For the End-User:'},
      'As an end-user, implementing Kompress.ai allows you to gain complete visibility over your compressors performance metrics. While the platform itself doesn’t immediately cut energy usage, it does provide you with critical data and insights to make informed decisions about how to run your equipment most efficiently. By monitoring real-time parameters, you can reduce wasted energy, lower operational costs, and ensure that your compressor is always running at peak performance. This proactive approach means you can focus on optimizing your processes while reducing maintenance costs and minimizing unplanned downtimes. ',
      {subAnswer: 'Here are a few specific use cases that helps to understand the benefits better:'},
      {subAnswer: '1. Identifying Over-Pressurization:'},
      'Problem: A common issue with screw air compressors is over-pressurization, where the compressor operates at a pressure level higher than necessary for the application.',
      'How Kompress.ai Helps: Through continuous real-time monitoring of pressure settings, Kompress.ai identifies instances of over-pressurization. By optimizing the pressure to the appropriate level, energy consumption can be significantly reduced.',
      'Example: If a compressor operates at 10 bar but the required pressure is only 8 bar, Kompress.ai can guide the adjustment to the lower pressure setting, saving up to 6-8% in energy costs immediately.',
      {subAnswer: 'Facts:'},
      'Power consumption increases as a result of over-pressurizing to compensate for pressure drops. e.g: every 2 psi increase in pressure results in 1% more energy consumption to maintain the desired cfm.',
      'Only 50% of a compressor\'s air supply is used for production & the rest is lost through waste and air leaks.',
      {subAnswer: 'Air is free! Compressed Air isn\'t FREE!'},
      {subAnswer: '2. Optimizing Load/Unload Cycles:'},
      'Problem: Inefficient load/unload cycles often lead to higher energy consumption because the compressor runs even when full capacity is not required.',
      'How Kompress.ai Helps: The solution monitors load/unload patterns in real-time and helps in adjustments to reduce idle times, thus minimizing wasted energy.',
      'Example: By analyzing the data, Kompress.ai might identify that the compressor is frequently operating in the unload state without actually producing compressed air. Adjusting the cycle timing can lower operational costs by as much as 15%.',
      {subAnswer: '3. Leak Detection: Problem:'},
      'Air leaks in the system are a significant source of energy loss, sometimes accounting for 20-30% of a compressor’s total energy consumption.',
      'How Kompress.ai Helps: The system continuously monitors pressure levels and identifies anomalies that may suggest leaks. Early detection allows for timely repairs, preventing substantial energy loss.',
      'Example: If a small leak consumes an extra 10 kW per hour, and it goes unnoticed for a month, it can result in hundreds of dollars in wasted energy. Kompress.ai’s early alerts based on thresholds set prevent such losses.',
      {subAnswer: '4. Data-Driven Energy Optimization:'},
      'Problem: Without actionable data, making informed decisions about energy efficiency can be challenging.',
      'How Kompress.ai Helps: The platform collects data on various parameters like pressure, temperature, run hours, and energy usage. This data is readily consumable by a certified air audit expert, who can further recommend settings and optimizations that drive down energy consumption.',
      'Example: By analyzing historical trends, Kompress.ai might help to suggest reducing operating hours during low demand periods, resulting in a 10-12% drop in energy costs.',
      {subAnswer: '5. Preventive Maintenance for Efficiency:'},
      'Problem: Worn-out components like filters, oil separators, or bearings reduce the efficiency of the compressor, leading to higher energy consumption.',
      'How Kompress.ai Helps: The platform predicts when maintenance is due, ensuring components are replaced before they start impacting efficiency.',
      'Example: Regularly changing a clogged air filter identified by Kompress.ai can lead to a 2-4% reduction in energy consumption, as the compressor won’t need to work as hard to maintain the desired pressure levels.'
    ]
  }
];

export default faqData;