import React, { useState } from "react";
import "font-awesome/css/font-awesome.css";

import Header from "./Components/Header";
import Footer from "./Components/Footer";

import "./Styles.css";

function Solutions() {
  const [cost, setCost] = useState("");
  const [hoursOfOperation, sethoursOfOperation] = useState("");
  const [motorHorsePower, setmotorHorsePower] = useState("");
  const [energy, setEnergy] = useState("");
  const [currency, setCurrency] = useState("$");

  const calculateEnergy = () => {
    if (cost !== "" && hoursOfOperation !== "" && motorHorsePower !== "") {
      setEnergy(cost * hoursOfOperation * motorHorsePower * 0.746);
    }
  };

  return (
    <React.Fragment>
      <Header />

      <div
        className="container-fluid solutions-bg"
        style={{ paddingTop: "100px" }}
      >
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-md-10 col-sm-12 text-center">
              <img
                alt=""
                src={require("./images/kompress_logo_black.png")}
                width="200px"
              />
              <p className="para green-para">
                Real-time analytics for efficient performance
              </p>
              <p className="para solution-banner-para">
                Compressors contribute almost 10% of the total energy cost of an
                industrial plant. With Kompress.ai solution, industries can
                monitor the health of their compressors in real-time, enabling
                efficient run times and in turn, significant energy savings.
                Powered by an advanced algorithm, Kompress.ai enables monitoring
                of critical parameters and analytics to derive specific
                performance and utilization insights. It can be integrated with
                any existing ERP/data management IOT layer already deployed at
                the plant. We also provide retrofitting to existing
                installations. We currently work directly with many leading air
                compressor manufacturers and also global industry users of
                compressors such as Oil & Gas, Automotive, Pharmaceuticals,
                Bottling Plants, Drilling and Construction, and Cement, among
                other industries.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid why-utv-container">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center brand-heading">
              Why Kompress.ai
            </div>

            <div className="col-12">
              <div className="row why-utv-panel-column solutions">
                <div className="col col-md-3 col-sm-12 text-center why-utv-panel">
                  <img alt="" src={require("./images/why_utv_1.png")} />
                  <p className="para pink-para">Reduced Energy costs</p>
                  <h2 className="sub-heading">
                    Saves 15% on energy cost and 10% on maintenance costs of
                    your compressors
                  </h2>
                  <span id="energy">&nbsp;</span>
                </div>
                <div className="col col-md-3 col-sm-12 text-center why-utv-panel">
                  <img alt="" src={require("./images/why_utv_2.png")} />
                  <p className="para pink-para">Zero unplanned downtimes</p>
                  <h2 className="sub-heading">
                    Predicts and manage operational disruptions from unplanned
                    servicing and fault detection
                  </h2>
                </div>
                <div className="col col-md-3 col-sm-12 text-center why-utv-panel">
                  <img alt="" src={require("./images/why_utv_3.png")} />
                  <p className="para pink-para">Stay connected remotely</p>
                  <h2 className="sub-heading">
                    Monitor compressor health through an app remotely and stay
                    connected 24/7
                  </h2>
                </div>
                <div className="col col-md-3 col-sm-12 text-center why-utv-panel">
                  <img alt="" src={require("./images/why_utv_3.png")} />
                  <p className="para pink-para">
                    Optimize with actionable insights
                  </p>
                  <h2 className="sub-heading">
                    Keep compressor health in check with regular opetational
                    insights on the equipment
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid grey-bg">
        <div className="container">
          <div className="col-12 text-center brand-heading">
            Energy &amp; cost saving with Kompress.ai
          </div>
          <div className="col-12 text-center">
            <img
              alt=""
              src={require("./images/energy_1.png")}
              style={{ width: "100%" }}
              className="desktop-only"
            />
            <img
              alt=""
              src={require("./images/energy_1_mobile.png")}
              style={{ width: "100%" }}
              className="mobile-only"
            />
            <img
              alt=""
              src={require("./images/energy_2.png")}
              style={{ width: "100%" }}
              className="desktop-only"
            />
            <img
              alt=""
              src={require("./images/energy_2_mobile.png")}
              style={{ width: "100%" }}
              className="mobile-only"
            />
          </div>

          <div className="colmd--12" style={{ paddingTop: "0px" }}>
            <div className="col-12 text-center brand-heading">
              Calculate your current energy spend
            </div>
            <div className="row energy-calculator">
              <div className="col-md-12 text-center">
                <div class="styled-select">
                  <select
                    className="currency-selector"
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    <option
                      data-symbol="$"
                      value="$"
                      data-placeholder="0.00"
                      selected
                    >
                      USD
                    </option>
                    <option data-symbol="€" value="€" data-placeholder="0.00">
                      EUR
                    </option>
                    <option data-symbol="£" value="£" data-placeholder="0.00">
                      GBP
                    </option>
                    <option data-symbol="¥" value="¥" data-placeholder="0">
                      JPY
                    </option>
                    <option data-symbol="$" value="$" data-placeholder="0.00">
                      CAD
                    </option>
                    <option data-symbol="$" value="$" data-placeholder="0.00">
                      AUD
                    </option>
                    <option data-symbol="₹" value="₹" data-placeholder="0.00">
                      INR
                    </option>
                  </select>
                  <span class="fa fa-sort-desc"></span>
                </div>
                <div style={{ float: "left" }}>
                  <input
                    type="text"
                    className="subscribe-textbox"
                    placeholder="Cost per KWh"
                    required
                    name={cost}
                    onChange={(e) => setCost(e.target.value)}
                  />
                  <input
                    className="subscribe-textbox hours-of-operation-textbox"
                    placeholder="Hours of operation per year"
                    required
                    type="text"
                    name={hoursOfOperation}
                    onChange={(e) => sethoursOfOperation(e.target.value)}
                  />
                  <input
                    className="subscribe-textbox"
                    placeholder="Motor horsepower"
                    required
                    type="text"
                    name={motorHorsePower}
                    onChange={(e) => setmotorHorsePower(e.target.value)}
                  />
                  <input
                    className="subscribe-textbox clear-btn pink-btn schedulebtn"
                    required
                    style={{ color: "#ffffff" }}
                    type="button"
                    value="Calculate"
                    onClick={calculateEnergy}
                  />
                </div>
              </div>
              <span id="solutionArchitecture">&nbsp;</span>
            </div>

            <div className="row">
              <div className="col-md-12 text-center">
                <br />
                {energy !== "" && (
                  <React.Fragment>
                    <h2 className="sub-heading">
                      Current energy spend:{" "}
                      <span className="thick-font">
                        {" "}
                        {currency}
                        {energy}
                      </span>{" "}
                      per year{" "}
                    </h2>
                    <h3 className="sub-heading">
                      Talk to us to know potential energy savings!
                    </h3>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid" id="architecture">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center brand-heading solution-sub-heading">
              Architected for efficiency. Designed for scale.
            </div>
            <div className="col-12">
              <h3 className="sub-heading">
                Kompress.ai has been designed to be modular and compatible with
                any existing compressor of any type. The solution can be
                installed with minimal disruption to your existing operations.
                Robust sensors and a dedicated data network ensure you receive
                reliable updates in real-time.
              </h3>
            </div>

            <div className="col-md-12">
              <img alt="" src={require("./images/kompress.png")} width="100%" />
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid solutions-bg"
        style={{ paddingTop: "100px", paddingBottom: "50px" }}
      >
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-md-10 col-sm-12 text-center">
              <p className="para green-para">
                Monitor all the critical parameters
              </p>
            </div>

            <div className="col-12">
              <h3 className="sub-heading" style={{ color: "#ffffff" }}>
                With Kompress.ai installed, you will gain complete visibility on
                every aspect of a running compressor. A real-time view of all
                critical parameters will give you the advantage of planned and
                proactive maintenance, reducing breakdown calls for the OEMs and
                service providers.
              </h3>
            </div>

            <div className="col-12">
              <div
                className="row why-utv-panel-column solutions solution-four"
                style={{ justifyContent: "center" }}
              >
                <div className="col col-md-2 col-sm-3 text-center why-utv-panel">
                  <img alt="" src={require("./images/compressor.png")} />
                  <p className="para pink-para">Compressor Status (On/Off)</p>
                </div>
                <div className="col col-md-2 col-sm-3 text-center why-utv-panel">
                  <img alt="" src={require("./images/pressure.png")} />
                  <p className="para pink-para">Pressure Monitor</p>
                </div>
                <div className="col col-md-2 col-sm-3 text-center why-utv-panel">
                  <img alt="" src={require("./images/tempreature.png")} />
                  <p className="para pink-para">Temperature Checking</p>
                </div>
                <div className="col col-md-2 col-sm-3 text-center why-utv-panel">
                  <img alt="" src={require("./images/energy.png")} />
                  <p className="para pink-para">
                    Energy
                    <br /> Savings
                  </p>
                </div>
                <div className="col col-md-2 col-sm-3 text-center why-utv-panel">
                  <img alt="" src={require("./images/runhour.png")} />
                  <p className="para pink-para">Run Hours Monitoring</p>
                </div>
              </div>

              <div
                className="row why-utv-panel-column solutions solution-four"
                style={{ justifyContent: "center" }}
              >
                <div className="col col-md-2 col-sm-3 text-center why-utv-panel">
                  <img alt="" src={require("./images/airfilter.png")} />
                  <p className="para pink-para">Air Filter (Used Hours)</p>
                </div>
                <div className="col col-md-2 col-sm-3 text-center why-utv-panel">
                  <img alt="" src={require("./images/oilfilter.png")} />
                  <p className="para pink-para">
                    Oil Filter
                    <br /> (Used Hours)
                  </p>
                </div>
                <div className="col col-md-2 col-sm-3 text-center why-utv-panel">
                  <img alt="" src={require("./images/service-overdue.png")} />
                  <p className="para pink-para">
                    Service&nbsp;Overdue
                    <br /> Alerts
                  </p>
                </div>
                <div className="col col-md-2 col-sm-3 text-center why-utv-panel">
                  <img alt="" src={require("./images/voltage_control.png")} />
                  <p className="para pink-para">
                    Voltage
                    <br /> Control
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-12 text-center brand-heading solution-sub-heading">
              Power better decisions with remote tracking
            </div>
            <div className="col-12">
              <h3 className="sub-heading" style={{ width: "70%" }}>
                The Kompress.ai dashboard gives you an always-on, intelligent
                remote tracking analysis, allowing for greater performance
                visibility. Reduce downtime, save costs and increase RoI through
                a data-led decision support system.
              </h3>
              <p
                className="para green-para"
                style={{ textAlign: "center", fontSize: "15pt" }}
              >
                Real-time analytics for efficient performance
              </p>
            </div>

            <div className="col-md-8">
              <img alt="" src={require("./images/system.png")} width="100%" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default Solutions;
